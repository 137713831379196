<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        div(class="block-header") >>
          span(class="display-title") ブロックリスト

        div(class="block-list-content")
          div(v-for="item in list" class="block-list-account")
            div(@click="onAvatarClick(item.profile.id)")
              pan-thumb(:avatar="item.profile.avatar_store_path" :attribute="item.profile.attribute" :current_title="item.profile.current_title")
              div {{ item.profile.account_name }}
            button(@click="onUnblock(item.profile.profileable.viewer_id)" class="pink-button") 解除

      div(class="content-right")
        pr-banner(v-if="!ui.profile")
        component-profile-index(v-if="ui.profile" :profile_id="profile_id" v-on:profileclose="profileclose()")

    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import { getBlockList, removeBlock } from '@/api/block';
import PanThumb from '@/components/PanThumb'

export default {
  name: "SettingBlockListIndex",
  components: { PanThumb },
  data() {
    return {
      profile_id: undefined,
      list: [],
      ui: {
        profile: false,
      },
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    await this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      const { data } = await getBlockList();
      this.list = data.data;
    },
    async onUnblock(viewer_id) {
      await removeBlock(viewer_id);
      await this.init();
    },
    profileclose() {
      this.ui.profile = false;
    },
    onAvatarClick(profile_id) {
      this.ui.profile = false;

      this.$nextTick(() => {
        this.profile_id = profile_id;
        this.ui.profile = true;
      })
    }
  }
};
</script>
